import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/Hero'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import SEO from '../components/seo'

const WebinarsPage = ({data, location}) => {
    const webinars = data.webinars.edges

    return (
        <Layout
          hero={
            <Hero
                // image={thoughts.hero_background}
                heading="Webinars"
                // subhead={thoughts.hero_subhead}
            />
          }
        >
          <SEO
            title="Webinars"
            path={location.pathname}
          />
          <Container as="section">
            <Row>
                {webinars.map(({node}) => (
                    <div>
                        <h2>{node.title}</h2>
                        {node.body}
                    </div>
                ))}
            </Row>
          </Container>
        </Layout>
    )
}

export const query = graphql`{
    webinars: allButterPost(
      filter: {
        categories: {
          elemMatch: {
            slug: {eq: "webinar"}
          }
        }, 
        status: {
          eq: "published"
        }
      },
      sort: {
        order: ASC,
        fields: date
      }
    ) {
      edges {
        node {
          title
          summary
          body
        }
      }
    }
}
`

export default WebinarsPage